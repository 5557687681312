import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { useHeaderPinnedState } from 'components/Header/headerPinnedContext';
import { HEADER_HEIGHT } from 'components/Header';

const Wrapper = styled.div`
  transition: 0.3s;

  @media (min-width: ${breakpoints.md}) {
    margin-top: -312px;
    padding-bottom: 2rem;
    position: sticky;
    top: ${({ headerPinned }) => (headerPinned ? HEADER_HEIGHT + 32 : 32)}px;
  }
`;

function StickyPanel({ children, ...props }) {
  const headerPinned = useHeaderPinnedState();

  return (
    <Wrapper headerPinned={headerPinned} {...props}>
      {children}
    </Wrapper>
  );
}

StickyPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StickyPanel;
