import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { light, dark } from 'themes';
import breakpoints from 'utils/breakpoints';
import { HEADER_HEIGHT } from 'components/Header';
import Layout from 'components/Layout';
import Heading from 'components/Heading';
import Button from 'components/Button';
import StickyPanel from 'components/Careers/StickyPanel';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
`;

const Intro = styled.div`
  padding-top: ${HEADER_HEIGHT + 52}px;
  padding-bottom: 45px;
  background: #242424;
  color: #ffffff;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${HEADER_HEIGHT + 96}px;
    padding-bottom: 82px;
  }
`;

const Label = styled.div`
  padding: 0.5rem 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 0.875rem 0;
  }
`;

const Value = styled.div`
  font-weight: 700;
  text-transform: capitalize;
  padding: 0.5rem 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 0.875rem 0;
  }
`;

const ApplyButton = styled(Button)`
  width: 100%;
  margin: 2.8125rem 0;

  @media (min-width: ${breakpoints.md}) {
    margin: 0 0 1rem 0;
  }
`;

const TOC = styled.div`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 3px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0.25rem 0;

    @media (min-width: ${breakpoints.md}) {
      margin: 1.75rem 0;
    }

    li {
      font-size: 0.875rem;
      letter-spacing: 0;
      line-height: 1.75rem;
      position: relative;

      @media (min-width: ${breakpoints.md}) {
        font-size: 1rem;
      }

      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: -1px;
        content: ' ';
        background: #0276f1;
        width: 3px;
        height: 100%;
        opacity: 0;
        transition: 0.3s;
      }

      &:hover {
        color: #0276f1;

        &:before {
          opacity: 1;
        }
      }

      a {
        display: block;
        padding: 0.125rem 0.75rem;
        color: inherit;
        text-decoration: none;
        transition: 0.3s;

        @media (min-width: ${breakpoints.md}) {
          padding: 0.375rem 1.25rem;
        }

        &:visited {
          color: inherit;
        }

        &:hover,
        &:active,
        &:focus {
          color: #0276f1;
        }
      }
    }
  }
`;

const ContentWrapper = styled.div`
  font-size: 0.875rem;
  color: #1c2f45;
  letter-spacing: 0;
  line-height: 1.375rem;
  padding-bottom: 45px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1rem;
    line-height: 1.75rem;
    padding-bottom: 82px;
  }

  /* header */
  & > p > strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin: 1.5rem 0 2.5rem 0;
    font-family: Helvetica;
    font-size: 1.125rem;
    font-weight: 700;
    color: #1c2f45;
    letter-spacing: 0;
    line-height: 1.875rem;
  }

  p {
    margin: 1.75rem 0;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      padding: 0.25rem 0;

      &::before {
        content: '';
        display: inline-block;
        background: #0276f1;
        border-radius: 50%;
        margin-right: 0.75rem;
        height: 0.5rem;
        width: 0.5rem;
      }
    }
  }

  ol {
    padding: 0;
    list-style: none;
    counter-reset: li;

    li {
      padding: 0.25rem 0;
      counter-increment: li;

      &::before {
        content: '.' counter(li);
        display: inline-block;
        color: #0276f1;
        width: 0.875em;
        margin-right: 0.5em;
        text-align: right;
        direction: rtl;
      }
    }
  }
`;

function CareerDetail({ data }) {
  const { recruiterboxOpening } = data;
  const location =
    recruiterboxOpening.location &&
    [
      recruiterboxOpening.location.city,
      recruiterboxOpening.location.state,
      recruiterboxOpening.location.country,
    ]
      .filter(Boolean)
      .join(', ');

  return (
    <ThemeProvider theme={dark}>
      <Layout
        fullSize
        overflow
        pageTitle={`${recruiterboxOpening.title} - Careers`}
      >
        <GlobalStyle />
        <Intro>
          <Container>
            <Row>
              <Col md={8} lg={9}>
                <ThemeProvider theme={light}>
                  <Heading title={recruiterboxOpening.title} />
                </ThemeProvider>
              </Col>
            </Row>
            <Row>
              <Col md={1}>
                <Label>Location</Label>
              </Col>
              <Col md={7}>
                <Value>{location}</Value>
              </Col>
            </Row>
            <Row>
              <Col md={1}>
                <Label>Type</Label>
              </Col>
              <Col md={7}>
                <Value>{recruiterboxOpening.position_type}</Value>
              </Col>
            </Row>
            <Row>
              <Col md={1}>
                <Label>Category</Label>
              </Col>
              <Col md={7}>
                <Value>{recruiterboxOpening.tags.join(', ')}</Value>
              </Col>
            </Row>
          </Container>
        </Intro>
        <Container>
          <Row>
            <Col md={4} lg={3} push={{ md: 8, lg: 9 }}>
              {recruiterboxOpening.descriptionTOC && (
                <StickyPanel>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${recruiterboxOpening.hosted_url}?apply=true`}
                  >
                    <ApplyButton>Apply with your CV</ApplyButton>
                  </a>
                  <TOC
                    dangerouslySetInnerHTML={{
                      __html: recruiterboxOpening.descriptionTOC,
                    }}
                  />
                </StickyPanel>
              )}
            </Col>
            <Col md={7} lg={8} pull={{ md: 3 }}>
              <ContentWrapper
                dangerouslySetInnerHTML={{
                  __html: recruiterboxOpening.description,
                }}
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
}

CareerDetail.propTypes = {
  data: PropTypes.shape({
    recruiterboxOpening: PropTypes.shape({
      slug: PropTypes.string,
      rawID: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      team: PropTypes.string,
      created_date: PropTypes.number,
      position_type: PropTypes.string,
      description: PropTypes.string,
      descriptionTOC: PropTypes.string,
      state: PropTypes.string,
      is_remote_allowed: PropTypes.bool,
      location: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
      }),
      hosted_url: PropTypes.string,
    }),
  }).isRequired,
};

export default CareerDetail;

export const pageQuery = graphql`
  query($slug: String!) {
    recruiterboxOpening(slug: { eq: $slug }) {
      slug
      rawID
      tags
      title
      team
      state
      created_date
      position_type
      description: processedDescription
      descriptionTOC
      is_remote_allowed
      location {
        city
        state
        country
      }
      hosted_url
    }
  }
`;
